import * as React from 'react';

import { Home, Landing, Layout } from '../../components';

const FacebookPage = () => {
  return (
    <>
      <Landing.HeroSection leadSource="Facebook" />
      <Home.PropertyTaxServices />
      <Landing.ContactMini />
      <Layout.Footer />
    </>
  );
};

export default FacebookPage;

export const Head = () => <Layout.PageHead subtitle="Facebook Landing Page" googleNoIndex />;
